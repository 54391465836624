import React, { useState } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DangerousIcon from '@mui/icons-material/Dangerous';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Tooltip } from "@mui/material";


export default function VerifyVcModal({ isOpen, closeModal, responseVerifierVC }) {

    const [isCopiedVcId, setIsCopiedVcId] = useState(false)

    if (!isOpen) return null;

    const handleCopyId = (valueToCopy) => {
        navigator.clipboard.writeText(valueToCopy)
            .then(() => {
                setIsCopiedVcId(true);
                setTimeout(() => {
                    setIsCopiedVcId(false);
                }, 1000);
            }).catch((error) => {
                console.error('Failed to copy to clipboard:', error);
            });
    };

    function extractURLParts(input) {
        const match1 = input.match(/did:web:(.*):participant\/(.*)\/(.*)/i);
        const match2 = input.match(/did:web:(.*):participant:(.*)\/(.*)/i);
        if (match1) {
            return `https://${match1[1]}/${`participant/${match1[2]}/${match1[3]}`}`;
        } else if (match2) {
            return `https://${match2[1]}/${`participant/${match2[2]}/${match2[3]}`}`;
        } else {
            return input;
        }
    }

    function checkWarningsExist(array) {
        for (const item of array) {
            if (item.warning && item.warning.length > 0) {
                return false;
            }
        }
        return true;
    }

    return (
        <Dialog
            open={isOpen}
            onClose={closeModal}
            maxWidth="xl"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: {
                    maxHeight: '70vh',
                },
            }}
        >

            <DialogTitle id="alert-dialog-title">
                {
                    responseVerifierVC.statusCode === 200 ?
                        <div>
                            {
                                checkWarningsExist(responseVerifierVC.data) ? (
                                    <span style={{ fontSize: '32px', color: '#000F8E', textAlign: 'center' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="60" width="60" viewBox="0 0 512 512" fill="#2ECC71"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 48c110.5 0 200 89.5 200 200 0 110.5-89.5 200-200 200-110.5 0-200-89.5-200-200 0-110.5 89.5-200 200-200m140.2 130.3l-22.5-22.7c-4.7-4.7-12.3-4.7-17-.1L215.3 303.7l-59.8-60.3c-4.7-4.7-12.3-4.7-17-.1l-22.7 22.5c-4.7 4.7-4.7 12.3-.1 17l90.8 91.5c4.7 4.7 12.3 4.7 17 .1l172.6-171.2c4.7-4.7 4.7-12.3 .1-17z" /></svg>
                                        &ensp;This service is valid
                                    </span>
                                )
                                : (
                                    <span style={{ fontSize: '32px', color: '#000F8E', textAlign: 'center' }}>
                                         <svg xmlns="http://www.w3.org/2000/svg" height="60" width="60" viewBox="0 0 512 512" fill="#FF8A00"><path d="M256,0C114.8,0,0,114.8,0,256s114.8,256,256,256s256-114.8,256-256S397.2,0,256,0z M256,42.7 c118.1,0,213.3,95.3,213.3,213.3S374.1,469.3,256,469.3S42.7,374.1,42.7,256S137.9,42.7,256,42.7z M234.7,128v170.7h42.7V128H234.7 z M234.7,341.3V384h42.7v-42.7H234.7z" /></svg>
                                        &ensp;This service is valid but there are some warnings
                                    </span>
                                )
                            }

                        </div>
                        : <div>
                            <span style={{ fontSize: '32px', textAlign: 'center', color: '#000F8E' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="60" width="60" viewBox="0 0 16 16" fill='red'> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="red"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" fill="red"></path></svg>
                                &ensp;This service is invalid
                            </span>
                        </div>
                }
            </DialogTitle>

            <DialogContent sx={{ overflowX: 'hidden' }}>
                {
                    responseVerifierVC.statusCode === 200 || responseVerifierVC.statusCode === 403 ? responseVerifierVC.data.map((credential, index) => (
                        <div key={index}>
                            <span style={{ color: '#000F8E', cursor: 'pointer' }}>
                                <a href={extractURLParts(credential.vc_id)} target="_blank" rel="noopener noreferrer" style={{ color: '#000F8E' }}>
                                    {credential.vc_id.substring(0, 85)}... <OpenInNewIcon />
                                </a>
                                <Tooltip title={isCopiedVcId ? 'Copied !' : 'Copy'} arrow>
                                    <ContentCopyIcon onClick={() => handleCopyId(credential.vc_id)} style={{ cursor: 'pointer' }} />
                                </Tooltip>
                            </span>

                            <ul>
                                {
                                    !credential.is_valid && (
                                        credential.invalidRules.map((invalidRules, index) => (
                                            <li key={index}>
                                                {invalidRules.message} &nbsp;
                                                <DangerousIcon fontSize='small' color='error' />
                                                <span style={{ color: '#d32f2f', fontStyle: 'italic', fontWeight: 'bold' }}>Invalid</span>
                                            </li>
                                        )
                                        ))
                                }
                                {
                                    credential.warning && credential.warning.map((warning, index) => (
                                        <li key={index}>
                                            {warning.message} &nbsp;
                                            <WarningIcon fontSize='small' color='warning' />
                                            <span style={{ color: '#ed6c02', fontStyle: 'italic', fontWeight: 'bold' }}>Warning</span>
                                        </li>
                                    ))
                                }
                            </ul>

                            {index < responseVerifierVC.data.length - 1 && <hr />}
                        </div>
                    ))
                    : <div>
                        One or more VCs are not accessible
                    </div>
                }
            </DialogContent>

            <DialogActions>
                <Button onClick={closeModal} style={{ color: 'white', backgroundColor: "#000F8E" }} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};